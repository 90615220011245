import styled from 'styled-components';

export const BlogHeader = styled.div`
  padding: 140px 8px 60px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palatte.background.contrast};

  & > h1 {
    font-size: 6rem;
    line-height: 1.6;
    width: 100%;
    max-width: 1280px;

    & > span {
      height: 86px;
      background-color: ${({ theme }) => theme.palatte.text.default};
      padding: 1rem 2rem;
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    & > h1 {
      font-size: 3.2rem;
      line-height: 1.6;
    }
  }
`
export const BlogWrapper = styled.div`
  max-width: 1280px;
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px;

  @media (max-width: 768px) {
    padding: 16px;
  }
`;
export const BlogType = styled.h2`
  font-size: 2rem;
  margin-top: 100px;
  margin-bottom: 60px;
  font-family: Thicccboi, sans-serif;

`
export const BlogRow = styled.div`
  flex-direction: row;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin: 40px 0px;
  justify-content: center;
`;

export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 16px;

  & > div {
    border-radius: 8px;
    background: ${({ theme }) => theme.palatte.colors.thickyellow};
    padding: 8px 16px;
    margin-left: 5px;
    margin-right: 8px;

    box-shadow: -2px 2px 0px 2px rgba(61,107,251,1);
    font-family: Thicccboi, sans-serif;
    font-weight: 500;
  }
`

export const BlogItem = styled.div`
  width: 372px;
  overflow: hidden;
  display: flex;
  margin-right: auto;
  flex-direction: column;
  margin-bottom: 40px;

  & > a:first-child {
    border-radius: 24px;
    height: 240px;
    width: 372px;

    & > img {
      border-radius: 16px;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }


  @media (max-width: 400px) {
    width: calc(100vw - 48px);

    & > a:first-child {
      width: 100%;
      height: 200px;
    }
  
  }


  & > h3 {
    margin-top: 32px;
    font-family: Thicccboi, sans-serif;
    color: ${({ theme }) => theme.palatte.text.default};
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  & > p {
    opacity: .6;
    line-height: 1.4;
  }
`


export const MoreInfo = styled.a`
  text-align: right;
  margin-right: 2rem;
  margin-top: .5rem;
  align-items: center;
  display: flex;
  align-self: flex-end;
  text-decoration: none;
  color: ${({ theme }) => theme.palatte.colors.blue};

  & > div {
    margin-left: 8px;
    height: 40px;
    width: 40px;
    background-color: ${({ theme }) => theme.palatte.colors.blue};
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div:hover {
    cursor: pointer;
  }
`
// single blog

export const SingleWrapper = styled.article`
  max-width: 1280px;
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1280px) {
    padding: 0px 16px;
  }


  & > h3 {
    max-width: 692px;
    width: 100%;
    margin: 0;
  }

  & > h1 {
    width: 100%;
    max-width: 692px;
    font-size: 2.4em;
    margin-top: 0.125em;
  } 

  .cover-image {
    width: 100%;
    max-height: 500px;
    max-width: 692px;
    overflow: hidden;
    align-item: center;
    display: flex;
    margin-top: 1rem;

    & > img {
      object-fit: cover;
      width: 100%;
    }
  }

  * > img {
    width: 100%;
    max-height: 500px;
    max-width: 692px;
  }
`


export const BlogUserDetails = styled.div`
  max-width: 692px;
  width: 100%;
  margin-top: 3rem;
  font-size: 1.15rem;
  line-height: 2rem;

  
  & > div {
    display: flex;
    align-items: center;
    gap: 16px;  
    margin-top: 22px;

    & > img {
      width: 64px;
      height: 64px;
      border-radius: 100%;
    }

    & > div > h4:last-child {
      color: ${({theme}) => theme.palatte.colors.orange};
      font-size: 16px;
      margin: 0px;
    }

    & > div > h4 {
      font-size: 18px;
      margin: 0px;
      margin: 4px 0px 0px 0px;
      line-height: 1.7;
    }
  }

  & > .socials {
    margin-top: 22px;
  }

  & > p {
    font-size: 14px;
    line-height: 1.6;
    color: ${({theme}) => theme.palatte.text.default};
    opacity: 0.8;
  }


  `

export const Section = styled.section`
  max-width: 692px;
  width: 100%;
  margin-top: 2rem;
  font-size: 1.15rem;
  line-height: 2rem;

  & > div {
    & > p {
      
    }
    
    & > blockquote {
      border-left: 4px solid ${({ theme }) => theme.palatte.text.default};
      margin-left: 0px;
      padding-left: 24px;
      font-style: italic;
    }
    
    & > h2 {
      margin-top: 3rem;
    }
  }
`;
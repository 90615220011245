import React from "react";
import { BlogHeader, BlogType, BlogWrapper, BlogItem, MoreInfo, BlogRow, Tags } from "./Styled";
import MoreInfoIcon from '../../images/svgs/more-info.svg';

const Blog = () => {
  return (
    <>
      <BlogHeader>
        <h1>More resources for your <br /> <span>knowledge</span></h1>
      </BlogHeader>
      <BlogWrapper>
        {/* <BlogType>Startup</BlogType> */}
        <BlogRow>
          <BlogItem>
            <a href="/blog/saas-resilient-architectures">
              <img src="/images/blog/building-resilient-saas-architecture-patterns-and-best-practices.jpg" />
            </a>
            <Tags>
              <div>
                Technology
              </div>
              <div>
                15 Min. Read
              </div>
            </Tags>
            <h3>Building Resilient SaaS: Architecture Patterns and Best Practices</h3>
            <p>Explore why architecture is the backbone of successful SaaS systems and delve into the most impactful patterns for building applications that scale gracefully, perform reliably, and adapt to ever-changing demands.</p>
            <MoreInfo href="/blog/saas-resilient-architectures">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
          <BlogItem>
            <a href="/blog/points-when-building-social-media-platforms">
              <img src="/images/blog/points-when-building-social-media-platforms.jpg" />
            </a>
            <Tags>
              <div>
                Technology
              </div>
              <div>
                10 Min. Read
              </div>
            </Tags>
            <h3>Key points to remember when building social media platforms</h3>
            <p>Social media platforms are a bit different than other conventional platforms. There are multiple key points to remember before deciding what you would like to use and how to use them.</p>
            <MoreInfo href="/blog/points-when-building-social-media-platforms">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
          <BlogItem>
            <a href="/blog/common-mistakes-done-by-startup-founders">
              <img src="/images/blog/common-mistakes-done-by-startup-founders-cover.jpg" />
            </a>
            <Tags>
              <div>
                Startup
              </div>
              <div>
                8 Min. Read
              </div>
            </Tags>
            <h3>Common Mistakes done by startup founders — Expert Partner</h3>
            <p>Startups are exciting journeys that give ample experience in product development and expansion. But there could be some early hiccups that need to avoid in order to make it more exciting than a nightmare.</p>
            <MoreInfo href="/blog/common-mistakes-done-by-startup-founders">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
          <BlogItem>
            <a href="/blog/state-management-with-recoil-js">
              <img src="/images/blog/state-management-with-recoil-js-cover.jpg" />
            </a>
            <Tags>
              <div>
                Technology
              </div>
              <div>
                20 Min. Read
              </div>
            </Tags>
            <h3>State management with Recoil.js</h3>
            <p>In RecoilJs, the unexpected behavior (recoil) of a react app is controlled.

              Here it is referred to the unwanted re-renders of the components which consumes a lot of processing power and make the app less smooth in functioning.
            </p>
            <MoreInfo href="/blog/state-management-with-recoil-js">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
          <BlogItem>
            <a href="/blog/low-code-no-code-prototyping">
              <img src="/images/blog/no-code-cover.jpg" />
            </a>
            <Tags>
              <div>
                Artificial Intelligence
              </div>
              <div>
                10 Min. Read
              </div>
            </Tags>
            <h3>Low code or No Code prototyping revolution</h3>
            <p>Do not focus on writing a business plan on word, what you should focus on creating a prototype that works— Guy Kawasaki

              This universal truth has created more chances and opportunities to emerge and release a prototype faster in the current market.</p>
            <MoreInfo href="/blog/low-code-no-code-prototyping">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
          <BlogItem>
            <a href="/blog/sailing-through-the-tide-covid-19">
              <img src="/images/blog/covid-image.png" />
            </a>
            <Tags>
              <div>
                Startup
              </div>
              <div>
                10 Min. Read
              </div>
            </Tags>
            <h3>Sailing through the tide — COVID 19</h3>
            <p>The year 2020 was a year with many different outcomes. Some of the companies faced many hardships and some flourished and faced the pandemic very positively. </p>
            <MoreInfo href="/blog/sailing-through-the-tide-covid-19">
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
        </BlogRow>

        {/* <BlogType>Artificial Intelligence</BlogType> */}

        {/* <BlogRow>
       

        </BlogRow> */}
        {/* <BlogType>Technology</BlogType> */}

        {/* <BlogRow>
        

        </BlogRow> */}


        {/* <BlogType>Technology</BlogType>

        <BlogRow>
          <BlogItem>
            <div>
              <img src="/images/dummy-web.png" />
            </div>
            <h3>Web Technology</h3>
            <p>Implement beautiful and responsive user interfaces that are highly-interactive and give users a native-like experience.</p>
            <MoreInfo>
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
          <BlogItem>
            <div>
              <img src="/images/dummy-web.png" />
            </div>
            <h3>Web Technology</h3>
            <p>Implement beautiful and responsive user interfaces that are highly-interactive and give users a native-like experience.</p>
            <MoreInfo>
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
          <BlogItem>
            <div>
              <img src="/images/dummy-web.png" />
            </div>
            <h3>Web Technology</h3>
            <p>Implement beautiful and responsive user interfaces that are highly-interactive and give users a native-like experience.</p>
            <MoreInfo>
              <span>More Info</span>
              <div>
                <MoreInfoIcon />
              </div>
            </MoreInfo>
          </BlogItem>
        </BlogRow> */}
      </BlogWrapper>
    </>
  )
}

export default Blog;
